import {
  FETCH_ADMIN_EVENTS,
  FETCH_ADMIN_EVENT_DATA,
  FETCH_ADMIN_EVENT_TRAVELERS,
  TOGGLE_SELECTED_ADMIN_EVENT,
  CANCEL_EVENT,
  ACTIVATE_EVENT,
  DELETE_EVENT,
  CREATE_EVENT,
  EDIT_EVENT,
  DUPLICATE_EVENT,
  BULK_INVITE_TRAVELERS,
  INVITE_SINGLE_TRAVELER,
  SAME_DAY_REGISTER,
  DOWNLOAD_EVENT_TRAVELERS,
  CLEAR_EVENT_STATUSES_AND_ALERTS,
  REMOVE_EVENT_TRAVELER_REQUEST,
  REMOVE_EVENT_TRAVELER_SUCCESS,
  REMOVE_EVENT_TRAVELER_FAILURE,
  REMOVE_EVENT_TRAVELER_CLEAR,
  TOGGLE_EVENT_TRAVELER_REQUEST,
  TOGGLE_EVENT_TRAVELER_SUCCESS,
  TOGGLE_EVENT_TRAVELER_FAILURE,
  TOGGLE_EVENT_TRAVELER_CLEAR,
} from '../actions/types';

const initialState = {
  loading: true,
  brochureLoading: true,
  event: {},
  events: [],
  travelers: [],
  selectedEvent: {},
  deleteCode: null,
  activateCode: null,
  createCode: null,
  eventData: {},
  editCode: null,
  bulkInviteNotice: null,
  bulkInviteAlert : null,
  eventId: null,
  bulkInviteCode: null,
  sameDayCode: null,
  travelersCsv: null,
  singleInviteNotice: null,
  singleInviteError : null,
  travelersDeleted : {
    data: null,
    loading: false,
    error: false,
    message: null,
  },
  travelersStatusToggle : {
    data: null,
    loading: false,
    error: false,
    message: null,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMIN_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false,
        selectedEvent:
          action.payload.filter(event => new Date(event.start) >= Date.now() && !event.deleted).reverse()[0] || {},
      };
    case FETCH_ADMIN_EVENT_DATA:
      return {
        ...state,
        event: action.payload,
        brochureLoading: false,
      };
    case FETCH_ADMIN_EVENT_TRAVELERS:
      return {
        ...state,
        travelers: action.payload,
        bulkInviteCode: null,
        singleInviteCode: null,
        sameDayCode: null,
      };
    case TOGGLE_SELECTED_ADMIN_EVENT:
      return {
        ...state,
        selectedEvent: action.event,
      };
    case CANCEL_EVENT:
      return {
        ...state,
        deleteCode: action.code,
      };
    case ACTIVATE_EVENT:
      return {
        ...state,
        activateCode: action.code,
      };
    case DELETE_EVENT:
      return {
        ...state,
        deleteCode: action.code,
      };
    case CREATE_EVENT:
      return {
        ...state,
        createCode: action.code,
        eventData: action.data
      };
    case EDIT_EVENT:
      return {
        ...state,
        editCode: action.code,
      };
    case DUPLICATE_EVENT:
      return {
        ...state,
        eventId: action.eventId,
      };
    case BULK_INVITE_TRAVELERS:
      return {
        ...state,
        bulkInviteCode: action.code,
        bulkInviteNotice: action?.bulkInviteNotice,
        bulkInviteAlert: action?.bulkInviteAlert,
        travelersCsv: null,
      };
    case INVITE_SINGLE_TRAVELER:
      return {
        ...state,
        singleInviteCode: action.code,
        singleInviteNotice: action.notice,
        singleInviteError: action.error,
      };
    case SAME_DAY_REGISTER:
      return {
        ...state,
        sameDayCode: action.code,
      };
    case DOWNLOAD_EVENT_TRAVELERS:
      return {
        ...state,
        travelersCsv: action.payload,
      };
    case CLEAR_EVENT_STATUSES_AND_ALERTS:
      return {
        ...state,
        singleInviteCode: null,
        bulkInviteCode: null,
      };
    
    case REMOVE_EVENT_TRAVELER_REQUEST:
      return {
        ...state,
        travelersDeleted : { 
          data: null,
          loading: true,
          error: false,
          message: null,
        }
    };  
    case REMOVE_EVENT_TRAVELER_SUCCESS:
      return {
        ...state,
        travelersDeleted : { 
          data: action.data,
          loading: false,
          error: false,
          message: null,
        }
    };  
    case REMOVE_EVENT_TRAVELER_FAILURE:
      return {
        ...state,
        travelersDeleted : { 
          data: null,
          loading: false,
          error: false,
          message: action.message,
        }
    };  
    case REMOVE_EVENT_TRAVELER_CLEAR:
      return {
        ...state,
        travelersDeleted : { 
          data: null,
          loading: false,
          error: false,
          message: null,
        }
    };  

    case TOGGLE_EVENT_TRAVELER_REQUEST:
      return {
        ...state,
        travelersStatusToggle : { 
          data: null,
          loading: true,
          error: false,
          message: null,
        }
    };  
    case TOGGLE_EVENT_TRAVELER_SUCCESS:
      return {
        ...state,
        travelersStatusToggle : { 
          data: action.data,
          loading: false,
          error: false,
          message: null,
        }
    };  
    case TOGGLE_EVENT_TRAVELER_FAILURE:
      return {
        ...state,
        travelersStatusToggle : { 
          data: null,
          loading: false,
          error: false,
          message: action.message,
        }
    };  
    case TOGGLE_EVENT_TRAVELER_CLEAR:
      return {
        ...state,
        travelersStatusToggle : { 
          data: null,
          loading: false,
          error: false,
          message: null,
        }
    };  

    default:
      return state;
  }
}
