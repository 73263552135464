import { FETCH_EXCHANGE_BALANCE_REQUEST,
    FETCH_EXCHANGE_BALANCE_SUCCESS,
    FETCH_EXCHANGE_BALANCE_CLEAR,
    FETCH_EXCHANGE_BALANCE_FAILURE ,
    UPDATE_EXCHANGE_BALANCE_REQUEST,
    UPDATE_EXCHANGE_BALANCE_SUCCESS,
    UPDATE_EXCHANGE_BALANCE_CLEAR,
    UPDATE_EXCHANGE_BALANCE_FAILURE,
    FETCH_BALANCE_VALUE_REQUEST,
    FETCH_BALANCE_VALUE_SUCCESS,
    FETCH_BALANCE_VALUE_CLEAR,
    FETCH_BALANCE_VALUE_FAILURE
} from "../actions/types";

const initialState = {
    fetchExchangeBalance: {
        data: null,
        loading: false,
        error: null,
    },
    updateExchangeBalance: {
        data: null,
        loading: false,
        error: null,
    },
    fetchBalanceValue: {
        data: null,
        loading: false,
        error: null,
    }
}

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_EXCHANGE_BALANCE_REQUEST:
          return {
            ...state,
            fetchExchangeBalance: {
              data: null,
              loading: true,
              error: null,
            },
          };
        case FETCH_EXCHANGE_BALANCE_SUCCESS:
          console.log(action.payload, "fetch exchange balance");
          return {
            ...state,
            fetchExchangeBalance: {
              data: action.payload.contracts,
              loading: false,
              error: null,
            },
          };
        case FETCH_EXCHANGE_BALANCE_FAILURE:
          return {
            ...state,
            fetchExchangeBalance: {
              data: null,
              loading: false,
              error: 'error fetching contracts',
            },
          };
        case FETCH_EXCHANGE_BALANCE_CLEAR:
          return {
            ...state,
            fetchExchangeBalance: {
            data: null,
            loading: false,
            error: null,
            },
        };

        case FETCH_BALANCE_VALUE_REQUEST:
          return {
            ...state,
            fetchBalanceValue: {
              data: null,
              loading: true,
              error: null,
            },
          };
        case FETCH_BALANCE_VALUE_SUCCESS:
          return {
            ...state,
            fetchBalanceValue: {
              data: action.payload.balaceValueList,
              loading: false,
              error: null,
            },
          };
        case FETCH_BALANCE_VALUE_CLEAR:
          return {
            ...state,
            fetchBalanceValue: {
              data: null,
              loading: false,
              error: 'error fetching contracts',
            },
          };
        case FETCH_BALANCE_VALUE_FAILURE:
          return {
            ...state,
            fetchBalanceValue: {
            data: null,
            loading: false,
            error: null,
            },
        };

        case UPDATE_EXCHANGE_BALANCE_REQUEST:
          return {
            ...state,
            updateExchangeBalance: {
              data: null,
              loading: true,
              error: null,
            },
          };
        case UPDATE_EXCHANGE_BALANCE_SUCCESS:
          return {
            ...state,
            updateExchangeBalance: {
              data: action.payload,
              loading: false,
              error: null,
            },
          };
        case UPDATE_EXCHANGE_BALANCE_FAILURE:
          return {
            ...state,
            updateExchangeBalance: {
              data: null,
              loading: false,
              error: 'error updating exchange balance',
            },
          };
        case UPDATE_EXCHANGE_BALANCE_CLEAR:
          return {
            ...state,
            updateExchangeBalance: {
            data: null,
            loading: false,
            error: null,
            },
        };

        default:
            return state;
    }
}